<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/consumercard/card' }">消费卡列表</el-breadcrumb-item>
                <el-breadcrumb-item>添加</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" ref="form" :label-width="this.env.label_width">
                        <el-form-item label="消费卡名称">
                            <el-input v-model="form.consumer_card_title" maxlength="20"></el-input>
                        </el-form-item>
                        <el-form-item label="卡片背景">
                            <Qnupload v-model="form.consumer_card_bj_url" :sum="1" :compress="false"/>
                        </el-form-item>
                        <el-form-item label="预览">
                            <div class="card-preview" :style="{backgroundImage: `url(${form.consumer_card_bj_url})`, color:form.consumer_card_color}">
                                <div v-if="form.consumer_card_title">
                                    <div class="card-preview-title">
                                        <span>{{form.consumer_card_title}}</span>
                                    </div>
                                    <!-- 卡号 -->
                                    <div class="card-preview-number">
                                        <span>1234 0000 4567</span>
                                    </div>
                                    <!-- 持卡人姓名 -->
                                    <div>
                                        <span>持卡人: 练市集</span>
                                    </div>
                                    <div class="card-preview-number-info">
                                        <div class="card-preview-number-price">
                                            <span style="font-size: 18px">{{form.consumer_card_amount}}/</span>
                                            <span :style="{color:form.consumer_amount_color}">{{form.consumer_card_amount}}</span>
                                        </div>
                                        <div class="card-preview-number-date">
                                            <span>有效期至:{{form.consumer_card_time[1]}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="文字颜色">
                                    <div class="block">
                                        <el-color-picker v-model="form.consumer_card_color"></el-color-picker>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="总金额颜色">
                                    <div class="block">
                                        <el-color-picker v-model="form.consumer_amount_color"></el-color-picker>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="消费卡金额">
                            <el-input-number v-model="form.consumer_card_amount" :step="0.01" :min="0"
                                             :max="999999"
                                             step-strictly
                            ></el-input-number>
                        </el-form-item>
                        <el-form-item label="消费卡数量">
                            <el-input-number v-model="form.consumer_card_generate" :step="1" :min="0"
                                             :max="500"
                                             step-strictly
                            ></el-input-number>
                        </el-form-item>
                        <el-form-item label="消费卡有效期">
                            <el-date-picker
                                    v-model="form.consumer_card_time"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="is_auth('note.fictitious.addnote')"
                                       size="medium" type="primary" @click="save">保存
                            </el-button>
                            <el-button size="medium" @click="isreturn">返回</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        Qnupload,       // 上传组件
    },
    data() {
        return {
            select_loading: false,
            store_list: [],             // 门店列表
            topic_disabled: false,
            topic_tag: '',       // 话题标签
            topic_list: [],              //话题列表
            user_list: [],           //用户列表
            form: {
                consumer_card_title: '',        // 消费卡名称
                consumer_card_bj_url: '',       // 消费卡背景图url
                consumer_card_color: '',        // 消费卡字体颜色
                consumer_amount_color: '',        // 消费卡字体颜色
                consumer_card_amount: 0,        // 消费卡金额
                consumer_card_generate: 0,      // 消费卡数量
                consumer_card_time: [],          // 消费卡时间
                // consumer_card_scope: 1,         // 适用范围
                // compay_uuid_all: [],            // 商户信息
                // store_uuid_all: [],             // 门店信息
            },
            loading:true,
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 初始化
        init() {
            let consumer_card_uuid = this.$route.query.consumer_card_uuid
            if (consumer_card_uuid !== undefined) {
                this.consumer_card_uuid = consumer_card_uuid
                this.getinfo(consumer_card_uuid)
            } else {
                this.loading = false
            }
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 消费卡详情
        getinfo(consumer_card_uuid) {
            let postdata = {
                api_name: "consumer.card.getinfo",
                token: this.Tool.get_l_cache('token'),
                consumer_card_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 保存
        save() {
            let postdata = {
                api_name: "consumer.card.issave",
                token: this.Tool.get_l_cache('token'),
            }
            // 非必须参数（搜索条件）

            Object.assign(postdata, this.form);

            console.log('postdata', postdata)
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.page = 1
                            this.form = {}
                            this.$router.push({path: '/consumercard/card'})
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.go(-1)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-preview {
    width: 300px;
    height: 160px;
    border-radius: 10px;
    background: #f38c36;
    box-sizing: border-box;
    padding: 0 12px;
    background-position: center;
    background-size: 100%;
}
.card-preview-title{
    width: 100%;
    font-size: 17px;
    font-weight: bolder;
}
.card-preview-number{
    font-size: 24px;
    font-weight: bolder;
    letter-spacing: 4px;
    margin-top: 10px;
}
.card-preview-number-info{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
